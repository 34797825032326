export default (context, inject) => {
  const {
    store,
    app
  } = context

  /**
   * Get content
   * @returns content
   */
  const dataContent = () => {
    const cloneDeep = require('lodash/cloneDeep')
    const content = cloneDeep(store.state.contents.content || {})
    return content || {}
  }

  /**
   * Get menu locations
   * @param {String} location
   * @returns menu
   */
  const getMenuByLocation = (location) => {
    const content = dataContent().body || {}
    const menuLocations = content[location] || []
    return menuLocations
  }

  /**
   * Get home banner by locations
   * @param {String} locationCode
   * @returns banner
   */
  const getHomeBannerByLocation = (locationCode) => {
    const { homeBannerLocations } = dataContent().body || {}
    const bannerLocations = homeBannerLocations || []
    const banner = bannerLocations.filter(b => b.locationCode === locationCode)[0]
    return banner
  }

  /**
   * Add page view
   * @param {String} payload.pageUuid
   */
  const addContentPageView = async (payload) => {
    const { clientId } = store.state
    const setMemberUserRef = parseInt(app.$authUser.userRef(), 10) || 0
    const language = app.i18n.locale
    const {
      pageUuid,
      pageSourceUuid,
      pageSourceChannelCode
    } = payload
    const device = app.$device.isDesktopOrTablet ? 'Desktop' : 'Mobile'
    const options = {
      data: {
        pageUuid: pageSourceUuid || pageUuid,
        language,
        device,
        clientUuid: clientId,
        setMemberUserRef
      },
      config: {
        pageSourceChannelCode
      }
    }
    await store.dispatch('contents/addPageView', options)
  }

  /**
   * Add research page view
   * @param {String} payload.pageUuid
   */
  const addResearchPageView = async (payload) => {
    const { clientId } = store.state
    const setMemberUserRef = parseInt(app.$authUser.userRef(), 10) || 0
    const language = app.i18n.locale
    const {
      pageUuid,
      pageSourceUuid,
      pageSourceChannelCode
    } = payload
    const device = app.$device.isDesktopOrTablet ? 'Desktop' : 'Mobile'
    const options = {
      data: {
        researchUuid: pageSourceUuid || pageUuid,
        language,
        device,
        clientUuid: clientId,
        setMemberUserRef
      },
      config: {
        pageSourceChannelCode
      }
    }
    await store.dispatch('contents/addResearchPageView', options)
  }

  /**
   * Helpers
   */
  const pluginHelper = {
    isPreview () {
      const regxPreview = /^preview/i
      const { route } = context
      const { name } = route
      return regxPreview.test(name)
    }
  }

  /**
   * plugin content
   */
  const pluginContent = {
    async getPage (config) {
      const { path, device, errorConfig } = config
      const apiPath = '/api/cms/v1/pages/path'
      const options = {
        errorConfig,
        params: {
          path,
          device
        }
      }
      const response = await app.$apiPage.$get(apiPath, options)
      return response
    },
    async addPopularKeyword (keyword) {
      if (!keyword) { return }
      const setMemberUserRef = parseInt(app.$authUser.userRef(), 10) || 0
      const language = app.i18n.locale
      const data = {
        language,
        keyword,
        setMemberUserRef
      }
      const apiPath = '/api/cms-w/v1/search/keywords-popular'
      const options = {
        method: 'post',
        url: apiPath,
        data,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await app.$apiCms(options)
        .then(res => res)
        .catch(err => err)
    }
  }

  /**
   * Plugin Banner
   */
  const pluginBanner = {
    async getBannerByLocation (locationCode) {
      const { OK } = await require('http-status')
      const { getBannerLocation } = require('@/helpers/banner')
      const response = await getBannerLocation.call({ apiCms: app.$apiCms, i18n: app.i18n }, locationCode)
      if (response.status === OK) {
        return response.data
      }
      return null
    },
    getHomeBannerLocation (locationCode) {
      const data = getHomeBannerByLocation(locationCode) || {}
      return data.banner
    },
    updateClickCounter (itemUuId) {
      app.$apiCms
        .$post(`/api/cms-w/v1/banners/click/${itemUuId}`)
        .then(res => res)
        .catch(err => err)
    }
  }

  /**
   * Plugin Menu
   */
  const pluginMenu = {
    async getMenuByLocation (locationCode) {
      const { OK } = require('http-status')
      const { getMenuLocation } = await import('@/helpers/content')
      // const response = await store.dispatch('menu/getMenuByLocation', locationCode)
      const response = await getMenuLocation.call({ apiCms: app.$apiCms, i18n: app.i18n }, locationCode)
      if (response.status === OK) {
        return response.data
      }
      return null
    },
    getHomeMenuLocation (locationCode) {
      const menuLocations = getMenuByLocation('homeMenuLocations')
      const m = menuLocations.find(h => h.locationCode === locationCode)
      if (m) {
        return m.menu
      }
      return null
    },
    getLayoutMenuLocation (locationCode) {
      const menuLocations = getMenuByLocation('layoutMenuLocations')
      const m = menuLocations.find(h => h.locationCode === locationCode)
      if (m) {
        return m.menu
      }
      return null
    },
    addPageView (payload) {
      addContentPageView(payload)
    },
    /**
     * Add research, e-book and e-learning document view like page view
     * @param {String} payload.contentType
     * @param {String} payload.renderType // from search result
     * @param {String} payload.pageUuid
     * @param {Boolean} payload.isExternalUrl // knowledge external url
     */
    addDocumentView (payload) {
      if (!payload.contentType || !payload.pageUuid) { return }
      const { contentType } = require('@/utils')
      const type = payload.contentType
      const isELearning = type === contentType.eLearning
      const isEBookDetail = type === contentType.eBookDetail
      const isResearchSET = type === contentType.researchSET
      const isFileResearch = type === contentType.fileResearch
      const isResearchExtrnalLink = type === contentType.externalLink
      const isRenderTypeExternal = [contentType.externalLink, contentType.contentFile].includes(payload.renderType)
      const pageUuid = payload.pageUuid
      const { pageSourceUuid, pageSourceChannelCode } = payload
      if (isELearning === true || isEBookDetail === true || payload.isExternalUrl === true || isRenderTypeExternal) {
        addContentPageView({ pageUuid, pageSourceUuid, pageSourceChannelCode })
      }
      if (isResearchSET === true || isFileResearch === true || isResearchExtrnalLink === true) {
        addResearchPageView({ pageUuid, pageSourceUuid, pageSourceChannelCode })
      }
    }
  }

  /**
   * Plugin SEO
   */
  const pluginSEO = {
    setContentSEO (objSEO) {
      store.commit('contents/setContentSEO', JSON.parse(JSON.stringify(objSEO)))
    }
  }
  const plugin = { ...pluginHelper, ...pluginMenu, ...pluginContent, ...pluginBanner, ...pluginSEO }
  inject('content', plugin)
  context.$content = plugin
}
