/**
 * New uuid
 * @returns uuid
 */
const uuid = () => {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
  return uuid
}

/**
 * Element pdpa
 */
const elementPDPA = {
  name: 'pdpa'
}

/**
 * Render Type
 */
const contentRenderType = {
  fixedLayout: 'FixedLayout',
  pageBuilder: 'PageBuilder'
}
/**
 * Content Type
 */
const contentType = {
  eLearning: 'ELearning',
  eBookDetail: 'EBookDetail',
  researchSET: 'ResearchSET',
  manageInfographic: 'ManageInfographic',
  videoDetail: 'VideoDetail',
  videoPlaylist: 'VideoPlaylist',
  podcastDetail: 'PodcastDetail',
  podcastPlaylist: 'PodcastPlaylist',
  articleDetail: 'ArticleDetail',
  fileResearch: 'FileResearch',
  externalLink: 'ExternalLink',
  contentFile: 'ContentFile',
  setBook: 'SETBook'
}

export {
  uuid,
  elementPDPA,
  contentRenderType,
  contentType
}
