const contentGroupList = [
  {
    routeName: '^index_',
    teamName: '',
    contentType: 'Dynamic',
    contentSuperGroup: '',
    contentGroup: 'Welcome Page',
    contentCategory: ['welcome page'],
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/home',
    teamName: 'Digital Marketing',
    contentType: '',
    contentSuperGroup: '',
    contentGroup: '',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/search-result',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'Other',
    contentGroup: 'Search - Quote/Universal',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Overview',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/overview',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - Stock',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/search',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - Stock',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/etf/overview',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - ETF',
    productName: 'ETF',
    ignoreSlug: false
  },
  {
    path: '/market/product/etf/marketdata',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - ETF',
    productName: 'ETF',
    ignoreSlug: false
  },
  {
    path: '/market/product/dr/overview',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - DR',
    productName: 'DR',
    ignoreSlug: false
  },
  {
    path: '/market/product/drx/overview',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - DRx',
    productName: 'DRx',
    ignoreSlug: false
  },
  {
    path: '/market/product/drx/marketdata',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - DRx',
    productName: 'DRx',
    ignoreSlug: false
  },
  {
    path: '/market/product/drx/news',
    teamName: 'Digital Marketing',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Product - DRx',
    productName: 'DRx',
    ignoreSlug: false
  },
  {
    path: '/market/product/dw/overview',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - DW',
    productName: 'DW',
    ignoreSlug: false
  },
  {
    path: '/market/product/dw/search',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - DW',
    productName: 'DW',
    ignoreSlug: false
  },
  {
    path: '/market/product/dw/search/most-active-value',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - DW',
    productName: 'DW',
    ignoreSlug: false
  },
  {
    path: '/market/product/dw/search/most-active-underlying',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - DW',
    productName: 'DW',
    ignoreSlug: false
  },
  {
    path: '/market/product/dw/search/newly-listed',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - DW',
    productName: 'DW',
    ignoreSlug: false
  },
  {
    path: '/market/product/dw/search/nearly-expired',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - DW',
    productName: 'DW',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/price',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Price',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/financial-statement/company-highlights',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Financial Statement',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/financial-statement/latest/balance',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Financial Statement',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/financial-statement/latest/income',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Financial Statement',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/financial-statement/latest/cashflow',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Financial Statement',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/news',
    teamName: 'Digital Marketing',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - News',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/rights-benefits',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Benefits',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/company-profile/board-of-directors',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Board of director',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/company-profile/oppday-company-snapshot',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Company Snapshot',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/factsheet',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Factsheet',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/major-shareholders',
    // path: '/market/product/stock/quote/company-profile/major-shareholders',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Major shareholder',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Price',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/quote/company-profile/information',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Profile',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/etf/quote/price',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Price',
    productName: 'ETF',
    ignoreSlug: false
  },
  {
    path: '/market/product/etf/quote/rights-benefits',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Benefits',
    productName: 'ETF',
    ignoreSlug: false
  },
  {
    path: '/market/product/etf/quote/company-profile/information',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Profile',
    productName: 'ETF',
    ignoreSlug: false
  },
  {
    // path: '/market/product/etf/quote/company-profile/major-shareholders',
    path: '/market/product/etf/quote/major-shareholders',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Major shareholder',
    productName: 'ETF',
    ignoreSlug: false
  },
  {
    path: '/market/product/etf/quote/factsheet',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Factsheet',
    productName: 'ETF',
    ignoreSlug: false
  },
  {
    path: '/market/product/dr/quote/price',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Price',
    productName: 'DR',
    ignoreSlug: false
  },
  {
    path: '/market/product/dr/quote/rights-benefits',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Benefits',
    productName: 'DR',
    ignoreSlug: false
  },
  {
    path: '/market/product/dr/quote/company-profile',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Profile',
    productName: 'DR',
    ignoreSlug: false
  },
  {
    path: '/market/product/drx/quote/price',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Price',
    productName: 'DRx',
    ignoreSlug: false
  },
  {
    path: '/market/product/drx/quote/historical-trading',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Historical Trading',
    productName: 'DRx',
    ignoreSlug: false
  },
  {
    path: '/market/product/drx/quote/rights-benefits',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Benefits',
    productName: 'DRx',
    ignoreSlug: false
  },
  {
    path: '/market/product/drx/quote/company-profile',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Profile',
    productName: 'DRx',
    ignoreSlug: false
  },
  {
    path: '/market/product/dw/quote/price',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Quote - Price',
    productName: 'DW',
    ignoreSlug: false
  },
  {
    path: '/market/index/overview',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/agro',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/agro/agri',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/agro/food',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/consump',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/consump/fashion',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/consump/home',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/consump/person',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/fincial',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/fincial/bank',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/fincial/fin',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/fincial/insur',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/indus',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/indus/auto',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/indus/imm',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/indus/paper',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/indus/petro',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/indus/pkg',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/indus/steel',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/propcon',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/propcon/conmat',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/propcon/prop',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/propcon/pf&reit',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/propcon/cons',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/resourc',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/resourc/energ',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/resourc/mine',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/service',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/service/comm',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/service/helth',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/service/media',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/service/prof',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/service/tourism',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/service/trans',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/tech',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/tech/etron',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/tech/ict',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/index/gms-exchanges/trading',
    teamName: 'International Relations',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market Indices',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/investor-type',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics - Investor type',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/five-days',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics - Five days',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/nvdr/main',
    teamName: 'Thai NVDR',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics - NVDR',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/nvdr/trading-by-sector',
    teamName: 'Thai NVDR',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics - NVDR',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/nvdr/outstanding-share',
    teamName: 'Thai NVDR',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics - NVDR',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/nvdr/trading-by-stock',
    teamName: 'Thai NVDR',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics - NVDR',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/nvdr/trading-by-method',
    teamName: 'Thai NVDR',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics - NVDR',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/short-sales/total-short-sales',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics - Short sales',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/market-statistics/capital-raise',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/market-statistics/ipo',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/market-statistics/monthly-report',
    teamName: 'Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/statistics/market-statistics/monthly-report/etf',
    teamName: 'Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics',
    productName: 'ETF',
    ignoreSlug: true
  },
  {
    path: '/market/statistics/market-statistics/monthly-report/dw',
    teamName: 'Product',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics',
    productName: 'DW',
    ignoreSlug: true
  },
  {
    path: '/market/statistics/program-trading-value',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market statistics - Program Trading',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/news-and-alert/news',
    teamName: 'Digital Marketing',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Market - News and alert',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/news-and-alert/newsdetails',
    teamName: 'Digital Marketing',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Market - News and alert',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/news-and-alert/market-alerts',
    teamName: 'Listing & Disclosure',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Market - News and alert',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/news-and-alert/sign-posting',
    teamName: 'Listing & Disclosure',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Market - News and alert',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/news-and-alert/auditors-opinion',
    teamName: 'Listing & Disclosure',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Market - News and alert',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/news-and-alert/surveillance-c-sign-temporary-trading/temporary-trading',
    teamName: 'Listing & Disclosure',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Market - News and alert',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/news-and-alert/surveillance-c-sign-temporary-trading/market-surveillance-measure-list',
    teamName: 'Listing & Disclosure',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Market - News and alert',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/news-and-alert/surveillance-c-sign-temporary-trading/c-sign',
    teamName: 'Listing & Disclosure',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Market - News and alert',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/news-and-alert/trading-halt-suspension-pause',
    teamName: 'Listing & Disclosure',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Market - News and alert',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/news-and-alert/dynamic-price-band',
    teamName: 'Listing & Disclosure',
    contentType: 'News',
    contentSuperGroup: 'market',
    contentGroup: 'Market - News and alert',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/information/securities-list/concessions-list',
    teamName: 'Listing & Disclosure',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Related',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/information/member-list/main',
    teamName: 'Client & Product Support',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Member Lists',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/information/member-list',
    teamName: 'Client & Product Support',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Member Lists',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/stock-calendar/x-calendar',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Calendar',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/stock/',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/stock',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/warrants/',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/warrants',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/preferred-shares',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/preferred-shares/',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/etf',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'ETF',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/etf/',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'ETF',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/dr',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'DR',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/dr/',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'DR',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/drx',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'DRx',
    ignoreSlug: false
  },
  {
    path: '/market/get-quote/drx/',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Market - Search Symbol',
    productName: 'DRx',
    ignoreSlug: false
  },
  {
    path: '/listing/ipo/upcoming-ipo/set',
    teamName: 'Issuer',
    contentType: 'Dynamic',
    contentSuperGroup: 'listing',
    contentGroup: 'IPO',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/listing/ipo/upcoming-ipo/mai',
    teamName: 'Issuer',
    contentType: 'Dynamic',
    contentSuperGroup: 'listing',
    contentGroup: 'IPO',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/listing/ipo/upcoming-ipo/reit',
    teamName: 'Issuer',
    contentType: 'Dynamic',
    contentSuperGroup: 'listing',
    contentGroup: 'IPO',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/listing/ipo/upcoming-ipo/infrastructure-fund',
    teamName: 'Issuer',
    contentType: 'Dynamic',
    contentSuperGroup: 'listing',
    contentGroup: 'IPO',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/listing/ipo/upcoming-ipo/foreign-listing',
    teamName: 'Issuer',
    contentType: 'Dynamic',
    contentSuperGroup: 'listing',
    contentGroup: 'IPO',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/listing/ipo/ipo-statistics',
    teamName: 'Issuer',
    contentType: 'Dynamic',
    contentSuperGroup: 'listing',
    contentGroup: 'IPO',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/listing/ipo/first-trading-day',
    teamName: 'Issuer',
    contentType: 'Dynamic',
    contentSuperGroup: 'listing',
    contentGroup: 'IPO',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/listing/other-instruments/dw/eligible-underlying',
    teamName: 'Issuer',
    contentType: 'Dynamic',
    contentSuperGroup: 'listing',
    contentGroup: 'Listing - Others',
    productName: 'DW',
    ignoreSlug: false
  },
  {
    path: '/listing/other-instruments/dw/issuers',
    teamName: 'Issuer',
    contentType: 'Dynamic',
    contentSuperGroup: 'listing',
    contentGroup: 'Listing - Others',
    productName: 'DW',
    ignoreSlug: false
  },
  {
    path: '/listing/other-instruments/dw/outstanding-report',
    teamName: 'Issuer',
    contentType: 'Dynamic',
    contentSuperGroup: 'listing',
    contentGroup: 'Listing - Others',
    productName: 'DW',
    ignoreSlug: false
  },
  {
    path: '/rules-regulations/regulations',
    teamName: 'Regulations & Disciplinary Enforcement',
    contentType: 'Resource',
    contentSuperGroup: 'rules-regulations',
    contentGroup: 'Rules & Regulation',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/rules-regulations/circulars',
    teamName: 'Digital Marketing',
    contentType: 'Resource',
    contentSuperGroup: 'rules-regulations',
    contentGroup: 'Rules & Regulation',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/rules-regulations/forms',
    teamName: 'Digital Marketing',
    contentType: 'Resource',
    contentSuperGroup: 'rules-regulations',
    contentGroup: 'Rules & Regulation',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/rules-regulations/regulatory-statistics',
    teamName: 'Listing & Disclosure',
    contentType: 'Dynamic',
    contentSuperGroup: 'rules-regulations',
    contentGroup: 'Rules & Regulation',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/rules-regulations/exchange-enquiries',
    teamName: 'Listing & Disclosure',
    contentType: 'Dynamic',
    contentSuperGroup: 'rules-regulations',
    contentGroup: 'Rules & Regulation',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/rules-regulations/details',
    teamName: 'Listing & Disclosure',
    contentType: 'Dynamic',
    contentSuperGroup: 'rules-regulations',
    contentGroup: 'Rules & Regulation',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/rules-regulations/search-result',
    teamName: 'Regulations & Disciplinary Enforcement',
    contentType: 'Resource',
    contentSuperGroup: 'rules-regulations',
    contentGroup: 'Rules & Regulation - Search',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/rules-regulations/update/regulations',
    teamName: 'Regulations & Disciplinary Enforcement',
    contentType: 'Resource',
    contentSuperGroup: 'rules-regulations',
    contentGroup: 'Rules & Regulation - Rulebook',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/rules-regulations/update/circulars',
    teamName: 'Regulations & Disciplinary Enforcement',
    contentType: 'Resource',
    contentSuperGroup: 'rules-regulations',
    contentGroup: 'Rules & Regulation - Circular Letter',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/rules-regulations/update/forms',
    teamName: 'Regulations & Disciplinary Enforcement',
    contentType: 'Resource',
    contentSuperGroup: 'rules-regulations',
    contentGroup: 'Rules & Regulation - Form',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/rules-regulations/rules-canceled',
    teamName: 'Regulations & Disciplinary Enforcement',
    contentType: 'Resource',
    contentSuperGroup: 'rules-regulations',
    contentGroup: 'Rules & Regulation - Rulebook',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/knowledge/main',
    teamName: 'TSI',
    contentType: 'Dynamic',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - Finlit',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/knowledge/article',
    teamName: 'TSI',
    contentType: 'Article',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - Finlit',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/knowledge/video',
    teamName: 'TSI',
    contentType: 'Video',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - Finlit',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/knowledge/elearning',
    teamName: 'TSI',
    contentType: 'E-Learning',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - Finlit',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/knowledge/infographic',
    teamName: 'TSI',
    contentType: 'Infographic',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - Finlit',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/knowledge/ebook',
    teamName: 'TSI',
    contentType: 'E-Book',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - Finlit',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/knowledge/videoplaylist',
    teamName: 'TSI',
    contentType: 'Video Playlist',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - Finlit',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/knowledge/podcast',
    teamName: 'TSI',
    contentType: 'Podcast',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - Finlit',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/knowledge/podcastplaylist',
    teamName: 'TSI',
    contentType: 'Podcast Playlist',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - Finlit',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/search-result',
    teamName: 'TSI',
    contentType: 'Dynamic',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - Finlit',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/glossary',
    routeName: '^education-research-education-happymoney-glossary_',
    teamName: 'TSI',
    contentType: 'Dynamic',
    contentSuperGroup: 'education-research',
    contentGroup: 'Glossary',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/happymoney/glossary/slug',
    routeName: '^education-research-education-happymoney-glossary-slug_',
    teamName: '',
    contentType: 'Dynamic',
    contentSuperGroup: 'education-research',
    contentGroup: 'Glossary',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/research/overview',
    teamName: 'Research',
    contentType: 'Dynamic',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/research/market-report/main',
    teamName: 'Research',
    contentType: 'Dynamic',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/market-report/set-monthly-market-report',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/market-report/market-highlights',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/market-report/ceo-survey',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/market-report/investor-sentiment-index-cmri',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/market-report/international-roundups',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/article-paper/main',
    teamName: 'Research',
    contentType: 'Dynamic',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/article-paper/set-note',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/article-paper/set-cmri-research-paper',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/article-paper/set-cmri-working-paper',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/database/main',
    teamName: 'Research',
    contentType: 'Dynamic',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/database/market-microstructure/examples',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/database/setsmart/examples',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/database/cg-database/examples',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/database/economic-exposure-universe/examples',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/database/dividend-universe/examples',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/research-grant/all-research-grant',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/education-research/research/search-result',
    teamName: 'Research',
    contentType: 'Resource',
    contentSuperGroup: 'education-research',
    contentGroup: 'Research',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/about/event-calendar/holiday',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'about',
    contentGroup: 'Calendar',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/about/join-us/main',
    teamName: 'HR',
    contentType: 'Dynamic',
    contentSuperGroup: 'about',
    contentGroup: 'Career',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/about/mediacenter/main',
    teamName: 'Corp Com',
    contentType: 'Dynamic',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/about/mediacenter/vdo',
    teamName: 'Corp Com',
    contentType: 'Video',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/about/mediacenter/media-gallery/main',
    teamName: 'Corp Com',
    contentType: 'Dynamic',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/media-gallery/article',
    teamName: 'Corp Com',
    contentType: 'Article',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/media-gallery/video',
    teamName: 'Corp Com',
    contentType: 'Video',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/about/mediacenter/media-gallery/elearning',
    teamName: 'Corp Com',
    contentType: 'E-Learning',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/media-gallery/infographic',
    teamName: 'Corp Com',
    contentType: 'Infographic',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/media-gallery/presentation',
    teamName: 'Corp Com',
    contentType: 'Presentation',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/media-gallery/podcast',
    teamName: 'Corp Com',
    contentType: 'Podcast',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/insights/main',
    teamName: 'Corp Com',
    contentType: 'Dynamic',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/insights/article',
    teamName: 'Corp Com',
    contentType: 'Article',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/insights/video',
    teamName: 'Corp Com',
    contentType: 'Video',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/about/mediacenter/insights/elearning',
    teamName: 'Corp Com',
    contentType: 'E-Learning',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/insights/infographic',
    teamName: 'Corp Com',
    contentType: 'Infographic',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/insights/presentation',
    teamName: 'Corp Com',
    contentType: 'Presentation',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/news-release/main',
    teamName: 'Corp Com',
    contentType: 'Dynamic',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/news-release/article',
    teamName: 'Corp Com',
    contentType: 'Article',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/news-release/video',
    teamName: 'Corp Com',
    contentType: 'Video',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/news-release/elearning',
    teamName: 'Corp Com',
    contentType: 'E-Learning',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/news-release/infographic',
    teamName: 'Corp Com',
    contentType: 'Infographic',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/news-release/presentation',
    teamName: 'Corp Com',
    contentType: 'Presentation',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/search-result',
    teamName: 'Corp Com',
    contentType: 'Dynamic',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/about/mediacenter/yaek-yae/main',
    teamName: 'Corp Com',
    contentType: 'Dynamic',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/about/mediacenter/yaek-yae/article',
    teamName: 'Corp Com',
    contentType: 'Article',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/yaek-yae/video',
    teamName: 'Corp Com',
    contentType: 'Video',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/yaek-yae/elearning',
    teamName: 'Corp Com',
    contentType: 'E-Learning',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/yaek-yae/infographic',
    teamName: 'Corp Com',
    contentType: 'Infographic',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/yaek-yae/presentation',
    teamName: 'Corp Com',
    contentType: 'Presentation',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/about/mediacenter/yaek-yae/podcast',
    teamName: 'Corp Com',
    contentType: 'Podcast',
    contentSuperGroup: 'about',
    contentGroup: 'About - Media Center',
    productName: '',
    ignoreSlug: true
  },
  {
    path: '/tsd/overview',
    teamName: 'TSD',
    contentType: 'Dynamic',
    contentSuperGroup: 'tsd',
    contentGroup: 'TSD',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tsd/services/investors/transaction/certificate/name-changes-record',
    teamName: 'TSD',
    contentType: 'Dynamic',
    contentSuperGroup: 'tsd',
    contentGroup: 'TSD',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tsd/services/investors/calculators/stock-dividend',
    teamName: 'TSD',
    contentType: 'Dynamic',
    contentSuperGroup: 'tsd',
    contentGroup: 'TSD',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tsd/services/investors/calculators/right-offering',
    teamName: 'TSD',
    contentType: 'Dynamic',
    contentSuperGroup: 'tsd',
    contentGroup: 'TSD',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tsd/information/company-list',
    teamName: 'TSD',
    contentType: 'Dynamic',
    contentSuperGroup: 'tsd',
    contentGroup: 'TSD',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tsd/information/isin-cfi-fisn',
    teamName: 'TSD',
    contentType: 'Dynamic',
    contentSuperGroup: 'tsd',
    contentGroup: 'TSD',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tsd/information/debenture',
    teamName: 'TSD',
    contentType: 'Dynamic',
    contentSuperGroup: 'tsd',
    contentGroup: 'TSD',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tsd/rules-regulations/regulations',
    teamName: 'TSD',
    contentType: 'Resource',
    contentSuperGroup: 'tsd',
    contentGroup: 'TSD',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tsd/rules-regulations/circulars',
    teamName: 'TSD',
    contentType: 'Resource',
    contentSuperGroup: 'tsd',
    contentGroup: 'TSD',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tsd/rules-regulations/forms',
    teamName: 'TSD',
    contentType: 'Resource',
    contentSuperGroup: 'tsd',
    contentGroup: 'TSD',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tch/overview',
    teamName: 'TCH',
    contentType: 'Dynamic',
    contentSuperGroup: 'tch',
    contentGroup: 'TCH',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tch/rules-regulations/regulations',
    teamName: 'TCH',
    contentType: 'Resource',
    contentSuperGroup: 'tch',
    contentGroup: 'TCH',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tch/rules-regulations/circulars',
    teamName: 'TCH',
    contentType: 'Resource',
    contentSuperGroup: 'tch',
    contentGroup: 'TCH',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/tch/rules-regulations/forms',
    teamName: 'TCH',
    contentType: 'Resource',
    contentSuperGroup: 'tch',
    contentGroup: 'TCH',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/member/favorites',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/member/my-profile/main',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/member/my-profile/set-member-privilege',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/member/my-profile/training',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/member/my-profile/examination',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/member/my-profile/events',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/member/my-profile/check-registration-status',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/member/my-profile/past-training-examination',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'member',
    contentGroup: 'SET Member',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/about/event-calendar/event/overview',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'about',
    contentGroup: 'Calendar',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/about/event-calendar/event/eventdetails',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'about',
    contentGroup: 'Calendar',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/setbook/pocket-book/main',
    teamName: 'TSI',
    contentType: 'SET Book',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - SET Book',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/education-research/education/setbook/text-book/main',
    teamName: 'TSI',
    contentType: 'SET Book',
    contentSuperGroup: 'education-research',
    contentGroup: 'Education - SET Book',
    productName: '',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/top-ranking',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - Stock',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/market/product/stock/top-ranking',
    teamName: 'Digital Marketing',
    contentType: 'Dynamic',
    contentSuperGroup: 'market',
    contentGroup: 'Product - Stock',
    productName: 'Stock',
    ignoreSlug: false
  },
  {
    path: '/error/404',
    teamName: '',
    contentType: '',
    contentCategory: [],
    contentSuperGroup: 'error',
    contentGroup: 'error',
    productName: ''
  }
]

export {
  contentGroupList
}
