// const { handler } = require('@/api/utils/handler')
/**
 * State
 */
const state = () => ({
  shortsalesDefault: null
})

/**
  * Mutations
  */
const mutations = {
  setShortsales (state, data) {
    state.shortsalesDefault = data
  }
}

/**
  * Actions
  */
const actions = {
  async getShortsalesDeafult ({ commit }, payload) {
    try {
      const apiPath = '/api/set/shortsales/statistics/list'
      const { fromDate, toDate, symbols } = payload
      const options = {
        params: {
          fromDate,
          toDate,
          symbols
        }
      }
      const response = await this.$axios.$get(apiPath, options)
      commit('setShortsales', response)
      return response
    } catch (error) {
      return []
      // const { status, message } = handler(error)
      // if (status && message.message) {
      //   return 'Invalid selected period'
      // } else {
      //   return []
      // }
    }
  }
}

/**
  * Getters
  */
const getters = {
  getShortsales: (state) => {
    return state.shortsalesDefault ? state.shortsalesDefault : null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
