//
//
//
//
//
//

export default {
  name: 'TabPane',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fade: {
      type: Boolean,
      default: true
    }
  }
}
